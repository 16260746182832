<template>
  <div class="section__lk-content container-shadow fill-white m-0 pt-0">
    <div class="section__lk-subtitle"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: "ProfileViewHeader"
}
</script>

<style scoped>

</style>