<template>
  <div class="section__lk-content-item" @click="toForm">
    <div class="section__lk-content-item-left">
      <div class="icon test"></div>
      <div class="section__lk-content-item-separator"></div>
      <div class="section__lk-content-item-data">
        <div class="section__lk-content-item-data-title">
        </div>
        <div class="section__lk-content-item-data-content">
          <div>
            <div class="section__lk-content-item-data-title">
              {{ enter.form.title }}
            </div>
            <template v-if="status === 'active'">
              Перейти к заполнению <img src="/pic/profile/arrow-up-right.svg" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="section__lk-content-item-right section__lk-enroll">
      <div class="section__lk-enroll-icon d-flex flex-column">
        <div class="d-flex">
          <img src="/pic/profile/icon-time.svg" />
          <div>{{ enter.modified_at | formatDate }}</div>
        </div>
      </div>
      <div class="section__lk-enroll-status">
        <div class="status" :class="'status_' + status"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectionEnter",
  props: {
    enter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      status: 'active'
    }
  },
  mounted() {
    if(this.enter.result) {
      this.status = 'success'
    }
  },
  methods: {
    toForm() {
      return window.location.href = 'http://localhost:4200/forms/' + this.enter.form.alias
    }
  }
}
</script>

<style scoped>
.section__lk-content-item {
  align-items: center;
}
</style>