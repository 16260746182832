<template>
  <profile-view title="Отбор в тренеры" :subtitle="false">
    <template v-if="selection.matrices.length">
      <profile-view-header>Заполните матрицы самооценки:</profile-view-header>
      <selection-enter v-for="enter in selection.matrices" :enter="enter" :key="enter.id" description="Перейти к матрице"/>
    </template>
    <template v-if="selection.enters.length">
      <profile-view-header>Входная анкета:</profile-view-header>
      <selection-enter v-for="enter in selection.enters" :enter="enter" :key="enter.id" description="Перейти к анкете"/>
    </template>
  </profile-view>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProfileView from "@/components/profile/ProfileView.vue";
import ProfileViewHeader from "@/components/profile/ProfileViewHeader.vue";
import SelectionEnter from "@/components/profile/selection/SelectionEnter.vue";

export default {
  name: "Selection",
  components: {ProfileViewHeader, SelectionEnter, ProfileView},
  async mounted() {
    await this.getSelection()
  },
  computed: {
    ...mapGetters(['selection']),
    subtitle() {
      return this.selection.enters.length ? 'Входная анкета' : ''
    }
  },
  methods: {
    ...mapActions(['getSelection'])
  }
}
</script>

<style scoped>

</style>