<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">{{ title }}</div>
    <div class="section__lk-content-items mt-0">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileView",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>